import { graphql, Link } from "gatsby";
import { PageContext } from "gatsby/internal";
import * as React from "react";

import Pagination from "../../components/Pagination/Pagination";
import { SEO } from "../../components/SEO/SEO";
import { Category } from "../../contexts/blog/domain/categoty";
import { Post } from "../../contexts/blog/domain/post";
import { getCategoryMapped } from "../../contexts/blog/infrastructure/categorySanityDataMapper";
import { getPostMapped } from "../../contexts/blog/infrastructure/postSanityDataMapper";
import PostList from "../../views/blog/PostList/PostList";
import Hero from "../../views/shared/hero/Hero";
import Layout from "../../views/shared/layout/layout";

interface BlogPageContext extends PageContext {
  category: Category;
  posts: Post[];
}

const BlogPage: React.FC<BlogPageContext> = (props: BlogPageContext) => {
  const { data, pageContext } = props;
  const posts = [];
  const categories = [];

  data.allSanityPost.nodes.forEach((_post) => {
    posts.push(getPostMapped(_post));
  });

  data.allSanityCategory.nodes.forEach((_category) => {
    categories.push(getCategoryMapped(_category));
  });

  const categories_render = [];
  categories.forEach((category, index) => {
    categories_render.push(
      <Link
        key={`/blog-${category.slug}/`}
        to={`/${category.slug}/`}
        className={"a-category-link"}
      >
        {category.title}
      </Link>
    );
  });

  return (
    <Layout>
      <SEO title={"Blog | Obelion"} description={"Blog"} canonical={"blog"} />
      <Hero
        title={"Blog"}
        photo_alt={"Portada noticias"}
        photo={
          posts[Math.floor(Math.random() * (posts.length - 1))].imagen.asset
            .gatsbyImageData.images.fallback.src
        }
        color={"#A3BCC4"}
      >
        {categories_render}
      </Hero>
      <main id={"content-posts"}>
        <PostList posts={posts} />
        <Pagination
          nextPagePath={pageContext.nextPagePath}
          numberOfPages={pageContext.numberOfPages}
          pageNumber={pageContext.humanPageNumber}
          previousPagePath={pageContext.previousPagePath}
        />
      </main>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        ...Post
      }
    }
    allSanityCategory {
      nodes {
        slug {
          current
        }
        title
        _id
        description
      }
    }
    site {
      siteMetadata {
        projectId
        dataset
      }
    }
  }
`;
